import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const SeoVideoSection = ({ videos, single }) => {
	return (
		<section className="py-5 py-lg-7 ">
			<Container>
				{single !== true && (
					<Row className="g-4">
						{videos.nodes.map((video) => (
							<Col lg={6} className=" ">
								<div className="iframe-container-new">
									<iframe
										className="responsive-iframe"
										src={video.videoFields.url}
										title={video.title}
										frameborder="0"
										allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
										loading="lazy"
									></iframe>
								</div>
							</Col>
						))}
					</Row>
				)}
				{single === true && (
					<Row className="g-4">
						{videos.nodes.map((video) => (
							<Col lg={12} className=" ">
								<div className="iframe-container-new">
									<iframe
										className="responsive-iframe"
										src={video.videoFields.url}
										title={video.title}
										frameborder="0"
										allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
										loading="lazy"
									></iframe>
								</div>
							</Col>
						))}
					</Row>
				)}
			</Container>
		</section>
	);
};

export default SeoVideoSection;
