import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { GatsbyImage } from "gatsby-plugin-image";

const SeoResultsInYourArea = ({
	imgTop,
	imgTopAlt,
	imgBot,
	imgBotAlt,
	imgBot2,
	imgBot2Alt,
	heading,
	text,
	buttonText,
	buttonUrl,
	bottomContent,
}) => {
	return (
		<section className={`${bottomContent !== null ? "pb-5" : ""}`}>
			<div
				style={{
					background:
						"transparent linear-gradient(58deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
				}}
				className={` ${
					bottomContent !== null ? "py-5 py-lg-7" : "pt-lg-7  pt-5 pb-1"
				}`}
			>
				<Container className="mb-8">
					<Row className="g-lg-5 gx-lg-6 gx-xl-7">
						<Col xl={6}>
							<h2 className="fs-1 text-white mb-5">{heading}</h2>
							{text}
							<Button
								className=" w-100 w-md-auto mt-4 fs-5 primary-link py-2 px-4 "
								variant="white"
								as={Link}
								to={buttonUrl}
							>
								{buttonText}
							</Button>
						</Col>
						<Col lg={6} className="position-relative d-none d-xl-block pb-5">
							<div
								style={{
									borderRadius: " 15px",
									overflow: "hidden",
									boxShadow: " 0px 3px 99px #00000026",
									zIndex: 0,
									width: "22rem",
								}}
								className="bg-white  ms-auto mt-4 position-relative "
							>
								<GatsbyImage
									className="w-100 "
									image={imgTop}
									alt={imgTopAlt}
								/>
							</div>

							<div
								style={{
									borderRadius: " 15px",
									boxShadow: " 0px 3px 99px #00000026",
									overflow: "hidden",
									zIndex: 1,
									width: "20rem",
									top: "13rem",
								}}
								className="position-absolute ms-auto  "
							>
								<GatsbyImage
									className="w-100 "
									image={imgBot}
									alt={imgBotAlt}
									objectPosition="center center"
								/>
							</div>
							<div
								style={{
									overflow: "hidden",
									zIndex: 2,
									width: "20rem",
									top: "18rem",
									left: "13rem",
								}}
								className="position-absolute  ms-auto   "
							>
								<GatsbyImage
									className="w-100 "
									image={imgBot2}
									alt={imgBot2Alt}
									objectPosition="center center"
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			{bottomContent !== null && (
				<div style={{ top: "-5rem" }} className="position-relative mt-7 ">
					<Container>
						<Row className="justify-content-center">
							<Col lg={10} xl={9}>
								<Row
									style={{ borderRadius: "15px" }}
									className="g-6 pb-6 bg-white"
								>
									<Col>{bottomContent}</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</section>
	);
};

export default SeoResultsInYourArea;
